<template>
  <div class="main">
    <div class="main_box">
      <div v-html="dataBody"></div>
        <el-empty :image="require('@/assets/images/bjtsy/no_data.png')" :image-size="400" v-if="!dataBody"></el-empty>
    </div>
  </div>
</template>

<script>
  import cache from "@/libs/util.cache.js";
  export default {
    name: "tendencyChartDetails",
    data() {
      return {
        dataBody: ''
      }
    },
    mounted() {
      const obj = cache.getSessionStorage("tendencyChart")
      this.dataBody = obj.body
    }
  }
</script>

<style scoped lang="less">

</style>
